import { Component, OnInit } from '@angular/core';

@Component({ selector: 'app-external-page', templateUrl: './external-page.component.html', styleUrls: ['./external-page.component.scss'] })

export class ExternalPageComponent {
    constructor() { }

    checkIfNotInIframe()
    {
        return (window.self === window.top);
    }
}
