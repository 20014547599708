import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, isDevMode } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AccountModule } from './account/account.module';
import { BuyerModule } from './buyer/buyer.module';
import { ExternalModule } from './external/external.module';
import { MasterPageModule } from './general/master-page/master-page.module';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { PartManagerModule } from './part-manager/part-manager.module';
import { PartLocalManagerModule } from './part-local-manager/part-local-manager.module';
import { RepairerManagerModule } from './repairer-manager/repairer-manager.module';
import { RepairerSupervisorModule } from './repairer-supervisor/repairer-supervisor.module';
import { SharedModule } from './_modules/shared.module';
import { SellerModule } from './seller/seller.module';
import { SupportModule } from './support/support.module';

import { ErrorInterceptorService } from './_services/_interceptors/error-interceptor.service';
import { HttpInterceptorService } from './_services/_interceptors/http-interceptor.service';
import { SettingsService } from './_services/settings.service';

import { ExternalPageComponent } from './general/external-page/external-page.component';
import { NotFoundComponent } from './general/not-found/not-found.component';
import { PrintPageComponent } from './general/print-page/print-page.component';
import { TermosUsoComponent } from './general/termos-uso/termos-uso.component';

import { BuyerGuard } from './_services/_guards/buyer.guard';
import { BuyerManagerGuard } from './_services/_guards/comprador-gestor.guard';
import { BuyerSupervisorGuard } from './_services/_guards/comprador-supervisor.guard';
import { ExternalGuard } from './_services/_guards/external.guard';
import { LoginGuard } from './_services/_guards/login.guard';
import { SellerGuard } from './_services/_guards/seller.guard';
import { SellerLocalManagerGuard } from './_services/_guards/vendedor-gestor-loja.guard';
import { SellerPartManagerGuard } from './_services/_guards/vendedor-gestor-peca.guard';
import { SupportGuard } from './_services/_guards/support.guard';

import { Globals } from './_models/globals';
import { NgbDateCustomParserFormatter } from './_utils/calendar-custom-formatter';
import { RoutingState } from './_utils/routingstate';

import { TranslationResolver } from './_utils/translation.resolver';

export function HttpLoaderFactory(http: HttpClient) { return new TranslateHttpLoader(http); }

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt, 'pt-BR');

import './_utils/string-prototypes';
import { ManutencaoComponent } from './general/manutencao/manutencao.component';

@NgModule({
    declarations: [
        AppComponent,
        ExternalPageComponent,
        NotFoundComponent,
        ManutencaoComponent,
        PrintPageComponent,
        TermosUsoComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        NgSelectModule,
        TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] } }),

        AccountModule,
        BuyerModule,
        ExternalModule,
        MasterPageModule,
        PartManagerModule,
        PartLocalManagerModule,
        RepairerManagerModule,
        RepairerSupervisorModule,
        SharedModule,
        SellerModule,
        SupportModule
    ],
    providers:
        [
            { provide: LOCALE_ID, deps: [SettingsService], useFactory: (settingsService) => settingsService.getLocale() },
            { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
            { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
            { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },

            Globals,
            BuyerGuard,
            BuyerManagerGuard,
            BuyerSupervisorGuard,
            ExternalGuard,
            LoginGuard,
            RoutingState,
            SellerGuard,
            SellerLocalManagerGuard,
            SellerPartManagerGuard,
            SupportGuard,

            TranslationResolver
        ],
    bootstrap: [AppComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
