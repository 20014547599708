import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../../_services/general.service';

declare const $: any;

@Component({ selector: 'app-print-page', templateUrl: './print-page.component.html', styleUrls: ['./print-page.component.scss'] })
export class PrintPageComponent implements OnInit
{
    data: any[];
    printPage: any;
    type: string = '';

    constructor(private generalService: GeneralService, private route: Router) { }

    ngOnInit(): void {
        $('html').css('overflow', 'auto');
        $('body').css('backgroundColor', '#fff');
        $('.content-area').addClass('bkgprint');

        this.printPage = this.generalService.printPageData;

        if (!this.printPage)
            return;

        this.data = this.printPage.data;
        this.montarTela();

        if (this.printPage.header)
            $('.header').append(this.printPage.header);

        if (this.printPage.footer)
            $('.footer').append(this.printPage.footer);

        if (this.printPage.type)
            this.type = this.printPage.type;
    }

    montarTela() {
        // Monta o header
        let col = [];

        for (let i = 0; i < this.data.length; i++) {
            for (let key in this.data[i]) {
                if (col.indexOf(key) === -1) {
                    col.push(key);
                }
            }
        }

        // Cria a tabela dinamica
        let $table = $('.table');
        $table.find('thead tr').empty();
        $table.find('tbody').empty();

        // Cria o cabeçalho
        for (let i = 0; i < col.length; i++) {
            $table.find('thead tr').append(`<th>${col[i]}</th>`)
        }

        for (let line = 0; line < this.data.length; line++) {
            let row = '<tr>';

            for (let column = 0; column < col.length; column++)
            {
                let cell = `<td>${this.data[line][col[column]]}</td>`;
                row += cell;
            }

            row += '</tr>';
            $table.find('tbody').append(row);
        }

        window.print();
    }
}
