import { environment } from '../../environments/environment';
declare const CryptoJS: any;

export class EnvironmentKeys {
    private cryptKey: string = '}1P|i8e!5sR5?>O';
    crispWebsiteId: string = this.getEnvironmentVariable(environment.crispWebsiteId);
    googleAnalyticsKey: string = this.getEnvironmentVariable(environment.googleAnalyticsKey);

    private getEnvironmentVariable(variable: string): string {
        if (environment.useCrypt)
            return CryptoJS.AES.decrypt(variable, this.cryptKey).toString(CryptoJS.enc.Utf8);

        return variable;
    }
}
