import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, Route } from '@angular/router';

import { BaseGuard } from './base.guard';
import { AccountService } from '../../account/account.service';

@Injectable()
export class ExternalGuard extends BaseGuard {
    constructor(protected router: Router, protected accountService: AccountService) { super(router, accountService); }

    canActivate(routeAc: ActivatedRouteSnapshot) {
        if (routeAc.routeConfig.path === 'termos-uso')
            return true;

        this.verificarLogado();

        return true;
    }

    canLoad(route: Route) {
        this.verificarLogado();

        return true;
    }

    private verificarLogado() {
        let user = this.localStorageUtils.obterUsuario();
        if (this.localStorageUtils.isUsuarioCentral(user)) return

        if (user)
        {
            this.accountService.limparCookie();
            this.accountService.logoutWithoutRedirect();
        }
    }
}
