import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ReCaptcha2Component } from 'ngx-captcha';
import { TranslateService } from '@ngx-translate/core';

import { AccountService } from '../../account/account.service';
import { SettingsService } from '../../_services/settings.service';
import { NotificationService } from 'app/_services/notification.service';

@Component({ selector: 'forgot-password', templateUrl: './forgot-password.component.html', styleUrls: ['./forgot-password.component.scss'] })

export class EsqueciSenhaComponent implements OnInit
{
    model: any = { email: '' };
    captcha: any;
    esqueciMinhaSenhaForm: UntypedFormGroup;

    isLoading: boolean = false;

    @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

    constructor(private accountService: AccountService, private notificationService:  NotificationService, private formBuilder: UntypedFormBuilder, private ngxLoader: NgxUiLoaderService, private settings: SettingsService,
        private translate: TranslateService)
    {
        this.captcha = this.settings.getCaptchaData();
    }

    get f() { return this.esqueciMinhaSenhaForm.controls; }

    ngOnInit()
    {
        this.esqueciMinhaSenhaForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            recaptcha: ['', Validators.required]
        });
    }

    esqueciSenha()
    {
        if (this.esqueciMinhaSenhaForm.invalid)
            return;

        this.isLoading = true;

        this.accountService.rememberPassword(this.f.email.value, this.f.recaptcha.value).subscribe({
            next: () => {
                this.translate.get('login.forgot-password.mensagens.sucesso').subscribe(t => this.notificationService.showSuccessToastr(t));

                this.captchaElem.resetCaptcha();
                this.isLoading = false;
            },
            error: error => {
                console.log(error);
                this.captchaElem.resetCaptcha();

                if (error.status !== 500)
                    this.translate.get('login.forgot-password.mensagens.not_found').subscribe(t => this.notificationService.showErrorToastr(t));
                else
                    this.translate.get('geral.erro.erro-desconhecido').subscribe(t => this.notificationService.showErrorToastr(t));

                this.isLoading = false;
            }
        });
    }
}
