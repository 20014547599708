
<div class="content-area-body">
    <div class="content-area-body-titulo">
        <h1>{{ 'login.change-password.titulo' | translate}}</h1>
        <p>{{ 'login.change-password.subtitulo' | translate}}</p>
    </div>
    <form (ngSubmit)="alterarSenha()" [formGroup]="alterarSenhaForm" novalidate class="form-validate content-area-body-form" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
        <div class="mb-3 form-group">
            <label for="senha_atual">{{ 'login.change-password.senha_atual.label' | translate }}</label>
            <input type="password" #senha_atual formControlName="senha_atual" [placeholder]="'login.textbox.default-placeholder' | translate" class="form-control" maxlength="100" />
        </div>
        <div class="mb-3 form-group">
            <label for="nova_senha">{{ 'login.change-password.nova_senha.label' | translate }}</label>
            <input type="password" #nova_senha formControlName="nova_senha" [placeholder]="'login.textbox.default-placeholder' | translate" class="form-control" maxlength="100" />
        </div>
        <div class="mb-3 form-group">
            <label for="nova_senha_confirmacao">{{ 'login.change-password.nova_senha_confirmacao.label' | translate }}</label>
            <input type="password" #nova_senha_confirmacao formControlName="nova_senha_confirmacao" [placeholder]="'login.textbox.default-placeholder' | translate" class="form-control" maxlength="100" />
        </div>
        <div class="content-area-body-form-submit">
            <button customButton type="submit" [styleType]="'primary'" [isDisabled]="!alterarSenhaForm.touched" [isLoading]="isLoading" [label]="'login.change-password.botao.alterar' | translate"></button>
            <a (click)="voltar()" class="btn btn-link">{{'login.change-password.botao.voltar' | translate}}</a>
        </div>
    </form>
    <ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
</div>
