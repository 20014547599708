import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, Route, RouterStateSnapshot } from '@angular/router';

import { BaseGuard } from './base.guard';
import { AccountService } from '../../account/account.service';
import { RolesIdsEnum } from 'app/_models/roles';

@Injectable()
export class BuyerGuard extends BaseGuard {
    constructor(protected router: Router, protected accountService: AccountService) { super(router, accountService); }

    canActivate(routeAc: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //console.log('buyerguard.canActivate');
        this.verificarLogado(routeAc);
        return super.validarAcesso(routeAc, state);
    }

    canLoad(route: Route) {
        //console.log('buyerguard.canLoad');
        this.verificarLogado(null);
        return true;
    }

    private verificarLogado(routeAc: ActivatedRouteSnapshot) {
        let user = this.localStorageUtils.obterUsuario();

        if (!user || !user.homePage)
            this.navegarLogin(routeAc);

        // Se não for um usuário comprador ou um usuário da central
        if (user.role !== RolesIdsEnum.Comprador && !this.localStorageUtils.isUsuarioCentral(user))
            this.navegarHomePage(user.homePage);
    }
}
