<div [ngClass]="type">
    <div class="header"></div>
    <hr />
    <div class="body">
        <table class="table table-responsive table-striped">
            <thead>
                <tr></tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
    <div class="footer row"></div>
</div>
