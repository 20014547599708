<div class="content-area-body">
    <ul class="list-inline text-center text-sm">
        <div class="text-center mb-xl">
            <div class="text-lg mb-lg">404</div>
            <p class="lead m0">{{'geral.not-found.titulo' | translate}}</p>
            <p>{{'geral.not-found.subtitulo' | translate}}</p>
        </div>

        @if (menor1024) {
            <li class="list-inline-item">
                @if (pathIsLogin) {
                    <p>{{'geral.not-found.acesso-mobile.login' | translate }}</p>
                } @else {
                    <p>{{'geral.not-found.acesso-mobile' + (isZoomedIn ? '.zoom' : '.indevido') | translate }}</p>
                }
            </li>
        } @else {
            @if (pathIsLoggedIn) {
                <li class="list-inline-item">
                    @if (path) {
                        <p>{{'geral.not-found.sugestao' | translate}} <a [routerLink]="'/' + path">"{{ path }}"</a>?</p>
                    }
                </li>
            }

            @if (pathIsLogin) {
                <li class="list-inline-item">
                    {{'geral.not-found.login' | translate
                    }} <a routerLink="/login"> Login</a>.
                </li>
            }
            @if (pathIsHome) {
                <li class="list-inline-item">
                    {{'geral.not-found.home' | translate}} <a [routerLink]="'/' + homePage">{{ obterNomeTela() | translate}}</a>.
                </li>
            }
        }

        @if (!pathIsLogin) {
            <li class="buttons list-inline-item">
                <button class="btn btn-light" (click)="irTelaAnterior()">{{'elemento.generico-botao.voltar' | translate}}</button>
                <button class="btn btn-light" (click)="irTelaLogin()">{{'elemento.generico-botao.tela-login' | translate}}</button>
            </li>
        }
    </ul>
</div>
