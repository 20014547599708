import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SettingsService } from '../../_services/settings.service';

@Component({ selector: 'app-termos-uso', templateUrl: './termos-uso.component.html', styleUrls: ['./termos-uso.component.css'] })
export class TermosUsoComponent implements OnInit
{
    src: string;

    constructor(private settingsService: SettingsService, private route: Router) { }

    ngOnInit(): void
    {
        let rotas = this.route.url.split('/');
        let tipo = rotas[rotas.length - 1];

        this.settingsService.getTermosUsoVersion()
            .subscribe(
                response =>
                {
                    let versao = tipo === 'comprador' ? response.comprador : response.vendedor;
                    this.src = '/assets/documentos/termos-uso/' + versao + '/termos_uso_' + tipo + '.pdf';
                },
                error =>
                {
                    this.src = '/assets/documentos/termos-uso/v1/termos_uso_' + tipo + '.pdf';
                });
    }
}
