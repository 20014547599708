import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EnvironmentKeys } from 'app/_models/environmentKeys';

declare const crisp: any;

@Injectable({
    providedIn: 'root'
})
export class ScriptService {
    private environmentKeys: EnvironmentKeys;

    constructor(@Inject(DOCUMENT) private document: Document) {
        this.environmentKeys = new EnvironmentKeys();
    }

    loadScripts() {
        this.createCrispScript();
        this.createGoogleAnalyticsScript();
    }

    private createCrispScript(): void {
        const head = this.document.getElementsByTagName('head')[0];

        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        script.text = ` $crisp = [];
                        CRISP_WEBSITE_ID = '${this.environmentKeys.crispWebsiteId}';
                        CRISP_RUNTIME_CONFIG = { autoload: false };
                        (function () { d = document; s = d.createElement('script'); s.src = 'https://client.crisp.chat/l.js'; s.async = 1; d.getElementsByTagName('head')[0].appendChild(s); })();`;

        head.appendChild(script);
    }

    private createGoogleAnalyticsScript(): void {
        const head = this.document.getElementsByTagName('head')[0];

        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        script.text = ` window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${this.environmentKeys.googleAnalyticsKey}');`;

        head.appendChild(script);
    }
}
