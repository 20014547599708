import { Component, NgModule, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { AccountService } from '../../account/account.service';
import { User } from '../../_models/user';
import { getScreenSizeIsMobile } from 'app/_utils/general-utils';

declare const isEmpty: any;

@Component({ selector: 'app-not-found', templateUrl: './not-found.component.html', styleUrls: ['./not-found.component.css'] })
export class NotFoundComponent implements OnInit
{
    currentUser: User;
    homePage: string;
    path: string;
    pathIsHome: boolean;
    pathIsLogin: boolean;
    pathIsLoggedIn: boolean;
    router: Router;
    menor1024: boolean;
    isZoomedIn: boolean;

    constructor(private accountService: AccountService, private route: ActivatedRoute, private location: Location, private routerObject: Router)
    {
        this.pathIsHome = false;
        this.pathIsLogin = false;
        this.pathIsLoggedIn = false;
        this.menor1024 = getScreenSizeIsMobile();
        this.isZoomedIn = Math.round(window.devicePixelRatio) > 1;
        this.router = routerObject;
    }

    ngOnInit()
    {
        this.currentUser = this.accountService.usuarioLogado;
        this.homePage = (!isEmpty(this.currentUser)) ? this.currentUser.homePage : null;
        this.pathIsLoggedIn = !isEmpty(this.homePage);

        this.route.data.pipe(take(1))
            .subscribe((data: { path: string }) =>
            {
                this.path = data.path;
                this.pathIsHome = this.homePage !== null && this.currentUser && this.path !== '/' + this.homePage;
                this.pathIsLogin = this.homePage === null || (!this.currentUser && this.path !== '/login');
            });
    }

    irTelaAnterior()
    {
        this.path !== null ? this.location.back() : this.irTelaLogin();
    }

    irTelaLogin()
    {
        this.accountService.logout();
    }

    irPath()
    {
        if (this.currentUser.homePage != null && this.currentUser.homePage != undefined)
            this.router.navigateByUrl(this.currentUser.homePage);
    }

    obterNomeTela() {
        if (this.currentUser.homePage === null || this.currentUser.homePage === undefined)
            return '';

        return 'geral.menu.' + this.currentUser.homePage.replace('/', '.');
    }
}
