import { NgModule } from '@angular/core';

import { AppRoutingModule } from 'app/app-routing.module';

import { SharedModule } from 'app/_modules/shared.module';

import { LoginService } from 'app/account/login/login.service';

import { BuyerPecasCanceladasComponent } from 'app/buyer/_componentes/pecas-canceladas/pecas-canceladas.component';
import { MasterPageComponent } from './master-page.component';

@NgModule({
    declarations: [
        BuyerPecasCanceladasComponent,
        MasterPageComponent
    ],
    imports: [
        AppRoutingModule,
        SharedModule
    ],
    providers: [LoginService],
    bootstrap: []
})
export class MasterPageModule { }
