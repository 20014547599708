import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AccountService } from '../account/account.service';
import { LoginService } from './login/login.service';

import { EsqueciSenhaComponent } from '../account/forgot-password/forgot-password.component';
import { LoginComponent } from '../account/login/login.component';

import { SharedModule } from '../_modules/shared.module';

import { MatchValueDirective } from './change-password/match-value.directive';
import { AlterarSenhaComponent } from './change-password/change-password.component';

@NgModule({
    declarations: [
        AlterarSenhaComponent,
        EsqueciSenhaComponent,
        LoginComponent,
        MatchValueDirective
    ],
    imports: [RouterModule, SharedModule],
    providers: [
        AccountService, LoginService
    ],
    bootstrap: []
})
export class AccountModule { }
