import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'app/../environments/environment'

@Component({ selector: 'app-manutencao', templateUrl: './manutencao.component.html', styleUrls: ['./manutencao.component.css'] })
export class ManutencaoComponent
{
    constructor(protected router: Router) { 
        let maintenance = environment.inMaintenance;

        if (!maintenance)
            this.router.navigate(['']);
    }
}
