<main>
    <div class="image-area">
        <div class="image-area-footer">
            <div class="image-area-footer-top-section">
                <h1>{{ 'geral.external-page.titulo' | translate }}</h1>
                <img src="../../../assets/imagens/partsfy/logo_sem_texto_40x40.png" alt="partsfy">
            </div>
            <p>{{ 'geral.external-page.subtitulo' | translate }}</p>
        </div>
    </div>
    <div class="content-area">
        <div class="content-area-header">
            <img src="../../../assets/imagens/partsfy/logo-dark.png" alt="partsfy">
        </div>
        <router-outlet></router-outlet>
    </div>
</main>

