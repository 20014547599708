<div class="content-area-body">
    <div class="content-area-body-titulo">
        <h1>{{'login.forgot-password.titulo' | translate}}</h1>
        <p>{{'login.forgot-password.subtitulo' | translate}}</p>
    </div>
    <form (ngSubmit)="esqueciSenha()" [formGroup]="esqueciMinhaSenhaForm" novalidate class="form-validate content-area-body-form" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
        <div class="mb-3 form-group">
            <label for="email">{{'login.forgot-password.email.label' | translate}}</label>
            <input type="email" #email formControlName="email" placeholder="Digite" class="form-control" maxlength="100" />
        </div>
        <div class="mb-3 form-group">
            <div class="captcha col-md-12">
                <ngx-recaptcha2 #captchaElem formControlName="recaptcha" [siteKey]="captcha.sitekey" [theme]="captcha.theme"></ngx-recaptcha2>
            </div>
        </div>
        <div class="content-area-body-form-submit">
            <button customButton type="submit" [styleType]="'primary'" [isDisabled]="esqueciMinhaSenhaForm.invalid" [isLoading]="isLoading" [label]="'login.forgot-password.botao.enviar' | translate"></button>
            <a routerLink="/login" class="btn btn-link">{{'login.forgot-password.botao.voltar' | translate}}</a>
        </div>
    </form>
</div>
