import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { LocalStorageUtils } from '../../_utils/localstorage';
import { CancelHttpRequestsService } from '../cancel-http-requests.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    private version: string = environment.version;

    constructor(private cancelHttpRequestsService: CancelHttpRequestsService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
          map((event) => {
            return event;
          }),
          takeUntil(this.cancelHttpRequestsService.onCancelPendingRequests)
        );
    }
}
