import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AccountService } from './account/account.service';
import { GeneralService } from './_services/general.service';
import { ScriptService } from './_services/script.service';

import { RoutingState } from './_utils/routingstate';

import { environment } from '../environments/environment';

declare const $: any;

@Component({ selector: 'app-root', templateUrl: './app.component.html', styleUrls: ['./app.component.scss'] })
export class AppComponent implements AfterViewInit, OnInit {
    title = 'Partsfy';

    constructor(private accountService: AccountService, private generalService: GeneralService, private router: Router, private routingState: RoutingState, public translate: TranslateService, private script: ScriptService)
    {
        routingState.loadRouting();

        translate.addLangs(['pt-BR', 'en-US']);
        translate.setDefaultLang('pt-BR');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/pt-BR|pt-BR/) ? browserLang : 'pt-BR');
    }

    ngOnInit(): void {
        this.script.loadScripts();

        console.log(`Versão do Sistema: ${environment.version}`);

        let isLogged = this.accountService.checkIfIsLogged();

        if (isLogged) {
            let user = this.accountService.obterUsuario();
            this.generalService.updateUserPropertiesMixPanel(user).subscribe({ error: (e: any) => console.error(e) });
        }
    }

    ngAfterViewInit(): void
    {
        $.fn.select2.defaults.set("theme", "bootstrap");
        $.fn.select2.defaults.set("language", "es");
    }
}
