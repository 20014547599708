import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, Route, RouterStateSnapshot } from '@angular/router';

import { BaseGuard } from './base.guard';
import { AccountService } from '../../account/account.service';
import { RolesIdsEnum } from 'app/_models/roles';

@Injectable()
export class BuyerSupervisorGuard extends BaseGuard {
    constructor(protected router: Router, protected accountService: AccountService) { super(router, accountService); }

    canActivate(routeAc: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.verificarLogado();
        return super.validarAcesso(routeAc, state);
    }

    canLoad(route: Route) {
        this.verificarLogado();

        return true;
    }

    private verificarLogado() {
        let user = this.localStorageUtils.obterUsuario();

        if (!user || !user.homePage)
            this.navegarLogin();

        if (user.role !== RolesIdsEnum.SupervisorOficina)
            this.navegarHomePage(user.homePage);
    }
}
