Number.prototype.round = round;

String.prototype.removeAccent = removeAccent;
String.prototype.toHHMM = toHHMM;
String.prototype.toHHMMSS = toHHMMSS;
String.prototype.toPascalCase = toPascalCase;

interface Number
{
    round: typeof round;
}

interface String
{
    removeAccent: typeof removeAccent;
    toHHMM: typeof toHHMM;
    toHHMMSS: typeof toHHMMSS;
    toPascalCase: typeof toPascalCase;
}



function removeAccent()
{
    return this.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

function round(p)
{
    p = p || 10;
    return parseFloat(this.toFixed(p));
};

function toHHMM()
{
    var time = this.toHHMMSS();
    var hours = time.split(':')[0];
    var minutes = time.split(':')[1];

    return hours + ':' + minutes;
};

function toPascalCase()
{
    if (this === null || this === undefined || this.trim() === '')
        return '';

    return this.match(/[0-9a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ,.//\\()]+/gi)
        .map(function (word)
        {
            return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
        })
        .join(' ');
};

function toHHMMSS ()
{
    var sec_num = parseInt(this, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    let result_hours = (hours < 10) ? "0" + hours : hours;
    let result_minutes = (minutes < 10) ? "0" + minutes : minutes;
    let result_seconds = (seconds < 10) ? "0" + seconds : seconds;

    return result_hours + ':' + result_minutes + ':' + result_seconds;
};
