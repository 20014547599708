<div class="content-area-body">
    <div class="content-area-body-titulo">
        <h1>{{'login.titulo' | translate}}</h1>
        <p>{{'login.subtitulo' | translate}}</p>
    </div>
    <form (ngSubmit)="efetuarLogin()" [formGroup]="loginForm" novalidate class="form-validate content-area-body-form" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
        <div class="mb-3 form-group">
            <label for="login">{{'login.textbox.login.label' | translate}}</label>
            <input type="text" formControlName="login" [placeholder]="'login.textbox.default-placeholder' | translate" maxlength="100" autofocus />
        </div>
        <div class="mb-3 form-group">
            <label for="senha">{{'login.textbox.senha.label' | translate}}</label>
            <div class="input-group">
                <input [type]="showPassword ? 'text' : 'password'" formControlName="senha" [placeholder]="'login.textbox.default-placeholder' | translate" maxlength="100" />
                <i-feather [name]="showPassword ? 'eye-off' : 'eye'" class="eye" (click)="toggleShowPassword()"></i-feather>
            </div>

            <div class="forgot-password">
                <a [routerLink]="['/esqueci-senha']">{{'login.forgot-password.botao.abrir' | translate}}</a>
            </div>
        </div>
        <div class="content-area-body-form-submit">
            <button customButton type="submit" [styleType]="'primary'" [isDisabled]="loginForm.invalid" [isLoading]="isLoading" [label]="'login.botao.entrar' | translate"></button>
        </div>
    </form>
</div>
