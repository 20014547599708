import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';

import { AccountService } from '../account.service';
import { GeneralService } from '../../_services/general.service';
import { NotificationService } from '../../_services/notification.service';
import { SignalRService } from '../../_services/signalr.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Cashback } from '../_models/cashback';
import { Globals } from '../../_models/globals';
import { SweetAlertParams } from 'app/_models/notificationParams.model';
import { User } from '../../_models/user';

declare const $: any;
declare const isEmpty: any;

@Injectable({ providedIn: 'root' })
export class LoginService
{
    public onLoginFailedEvent: EventEmitter<string> = new EventEmitter();
    public returnUrl: string;

    constructor(private accountService: AccountService, private generalService: GeneralService, private notificationService: NotificationService,
        private signalRService: SignalRService, private globals: Globals,
        private ngxLoader: NgxUiLoaderService, private router: Router, private translate: TranslateService)
    {
        this.accountService.onLoginEvent.subscribe({ next: (ret) => { this.executeLoginResponse(ret); } });
    }

    private completeLoginProcess(global: any) {
        let currentUser = global.accountService.obterUsuario();

        if (isEmpty(currentUser))
        {
            console.log('Falha no login');
            global.setFailedLogin();
            return;
        }

        global.getIPAddress(global);
        global.getMultiLogin(global);

        if (global.accountService.checkUsuarioIsComprador(currentUser.role)) {
            global.getCashbackInfo(global, currentUser.id);
        }
    }

    public executeLoginResponse(response: any) {
        const userInfoData = response.user;

        this.setUserInfo(this, userInfoData, response.callback);
    }

    private getAllowedPages(global: any, callback: any) {
        let currentUser = global.accountService.obterUsuario();

        if (isEmpty(currentUser))
        {
            console.log('Falha no login');
            global.setFailedLogin();
            return;
        }

        global.accountService.getAllowedPages().subscribe({
            next: (ret: any) => {
                if (isEmpty(ret) || !ret.sucesso)
                {
                    console.log('Falha no login');
                    global.setFailedLogin();
                    return;
                }

                if (ret.retorno.length <= 0)
                {
                    console.log('Falha no login');
                    global.setFailedLogin();
                    return;
                }

                currentUser.pages = ret.retorno;
                currentUser.homePage = currentUser.pages[0].url;

                const setUserInternalInfoAndSignalR = setTimeout(() => global.setUserInternalInfoAndSignalR(global, callback), 50);
                global.accountService.saveUserCookie(currentUser, setUserInternalInfoAndSignalR);
            },
            error: error => {
                console.log(error);
                this.onLoginFailedEvent.emit('geral.erro.erro-desconhecido');
                global.ngxLoader.stopLoader('loader-principal');
            }
        });
    }

    private getCashbackInfo(global: any, cashbackUserId: number) {
        let currentUser = global.accountService.obterUsuario();

        if (isEmpty(currentUser)) {
            console.log('Falha no login');
            global.setFailedLogin();
            return;
        }

        global.accountService.getCashbackInfo(cashbackUserId).subscribe({
            next: (response: ApiResponseModel<Cashback>) => {
                if (!response || !response.success) {
                    console.log('Falha no login');
                    global.setFailedLogin();
                    return;
                }

                const nextCall = () => {
                    global.accountService.broadCastChannel.postMessage({ data: { tipo: 'SetCashbackEvent', cashback: response.result }});
                };

                global.accountService.updateUserCookie({ cashback: response.result }, nextCall);
            },
            error: error => {
                console.log(error);
                this.onLoginFailedEvent.emit('geral.erro.erro-desconhecido');
                global.ngxLoader.stopLoader('loader-principal');
            }
        });
    }

    private getIPAddress(global: any) {
        let currentUser = global.accountService.obterUsuario();

        if (isEmpty(currentUser))
        {
            console.log('Falha no login');
            global.setFailedLogin();
            return;
        }

        global.accountService.getLocalIPAddress().subscribe({
            next: (ret: any) => {
                global.accountService.updateUserCookie({ user_ip: ret.ip }, null);
            },
            error: error => console.log(error)
        });
    }

    private getMultiLogin(global: any) {
        let currentUser = global.accountService.obterUsuario();

        if (isEmpty(currentUser))
        {
            console.log('Falha no login');
            global.setFailedLogin();
            return;
        }

        global.accountService.getMultiLogin().subscribe({
            next: (ret: any) => {
                if (isEmpty(ret) || !ret.sucesso)
                {
                    console.log('Falha no login');
                    global.setFailedLogin();
                    return;
                }

                const nextCall = () => {
                    global.setCentralMultiLogin(global);
                    global.accountService.broadCastChannel.postMessage({ data: { tipo: 'SetMultiLoginEvent', dadosMultiUsuario: ret.retorno }});
                };
                global.accountService.updateUserCookie({ dadosMultiUsuario: ret.retorno }, nextCall);
            },
            error: error => {
                console.log(error);
                this.onLoginFailedEvent.emit('geral.erro.erro-desconhecido');
                global.ngxLoader.stopLoader('loader-principal');
            }
        })
    }

    private openTermsOfUse(userData: any, user_id: number, user_role: number, callback: any) {
        const params: SweetAlertParams = {
            cancelButtonText: 'login.termos-uso.eu-li',
            icon: 'warning',
            title: 'login.termos-uso.titulo',
            confirmButtonText: 'login.termos-uso.corpo',
            input: 'checkbox',
            inputValue: 0,
            inputValidator: () => {
                this.accountService.termosUso_Abrir(user_role);
                return 'termos-uso';
            },
            customClass: { validationMessage: 'swal-hidden', input: 'swal-hidden' }
        }

        this.notificationService.showConfirm(params).then((result) => {
            userData.first_login_date = new Date().toISOString();

            if (result.isDismissed && result.dismiss == Swal.DismissReason.cancel) {
                this.accountService.getLocalIPAddress().subscribe({
                    next: (response: ApiResponseModel<string>) => {
                        this.accountService.saveFirstLogin(response.result, userData.token).subscribe({
                            next: () => {
                                userData.is_use_terms_accepted = true;

                                if (typeof callback === 'function')
                                    callback(userData);

                                this.accountService.trocarUsuarioComum(user_id, false, null, null);
                                //this.notificationService.showSuccessToastr(this.globals.translate('login.first-login.mensagens.sucesso.corpo'), this.globals.translate('login.first-login.mensagens.sucesso.titulo'));
                            }
                        });
                    },
                    error: error => console.log(error)
                });
            }
        });
    }

    private redirectUserAfterLogin(global: any, currentHomePage: string) {
        let currentUser = global.accountService.obterUsuario();

        if (isEmpty(currentUser))
        {
            console.log('Falha no login');
            global.setFailedLogin();
            return;
        }

        if (global.returnUrl)
            global.router.navigateByUrl(global.returnUrl);
        else
            global.router.navigateByUrl(currentHomePage);
    }

    private setCentralMultiLogin(global: any) {
        let currentUser = global.accountService.obterUsuario();

        if (isEmpty(currentUser))
        {
            console.log('Falha no login');
            global.setFailedLogin();
            return;
        }

        // Se for usuário da central
        if (global.accountService.checkUsuarioIsCentral(currentUser) && !isEmpty(currentUser.dadosMultiUsuario) && currentUser.dadosMultiUsuario.length > 0) {
            // Busca o cashback do primeiro usuário e salva o usuário interno
            const firstLocalUser = currentUser.dadosMultiUsuario[0];

            if (isEmpty(firstLocalUser)) {
                global.accountService.saveUserInternalCookie(currentUser, null);
                return;
            }

            global.getCashbackInfo(global, firstLocalUser.id);
            global.accountService.trocarUsuarioCentral(firstLocalUser.id, currentUser, null, true);
        }
    }

    private setFailedLogin() {
        this.accountService.logoutWithoutRedirect();
        this.ngxLoader.stopLoader('loader-principal');

        this.onLoginFailedEvent.emit(null);
    }

    private setUserInternalInfoAndSignalR(global: any, callback: any)
    {
        let currentUser = global.accountService.obterUsuario();

        if (isEmpty(currentUser))
        {
            console.log('Falha no login');
            global.setFailedLogin();
            return;
        }

        const nextCall = () =>
        {
            global.completeLoginProcess(global);
            global.accountService.setarUrlsPermitidas(0);
            global.signalRService.inicializar(global.accountService);

            if (typeof callback === 'function')
                callback();
            else
                global.redirectUserAfterLogin(global, currentUser.homePage);
        };

        global.accountService.saveUserInternalCookie(currentUser, nextCall);
    }

    private setUserInfo(global: any, userData: any, callback: any) {
        const token = userData.token;
        const tokenPayload = this.accountService.obterUsuarioTokenPayload(token);

        const user: User = {
            id: userData.id,
            name: tokenPayload.name,
            firstName: tokenPayload.name,
            username: userData.username,
            token: token,
            chat_id: userData.chat_id,
            culture: tokenPayload.culture,
            version: tokenPayload.version,

            user_company_id: tokenPayload.user_company_id,
            holding_id: userData.holding_id,
            email: tokenPayload.email,
            role: tokenPayload.role,
            plan: tokenPayload.plan,
            company_id: tokenPayload.company_id,

            current_company_name: userData.current_company_name,
            isAdmin: tokenPayload.is_admin,
            is_use_terms_accepted: userData.is_use_terms_accepted,

            has_cashback: userData.has_cashback,
            part_type: userData.part_type,
            holding_type_id: userData.holding_type_id,
            is_billable: userData.is_billable,
            first_login_date: userData.first_login_date,

            homePage: null,
            password: null,
            holding_name: null,
            phone_radio: null,
            user_ip: null,
        };

        this.translate.use(user.culture);

        if (isEmpty(user.first_login_date) && !user.isAdmin) {
            const nextCall = (userData) => global.setUserInfo(global, userData);
            this.openTermsOfUse(userData, user.id, user.role, nextCall);
        }
        else {
            const getAllowedPages = () => setTimeout(() => global.getAllowedPages(global, callback), 50);
            global.accountService.saveUserCookie(user, getAllowedPages);
        }
    }
}
