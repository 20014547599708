import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EsqueciSenhaComponent } from './account/forgot-password/forgot-password.component';
import { ExternalPageComponent } from './general/external-page/external-page.component';
import { LoginComponent } from './account/login/login.component';

import { PathResolveService } from './_services/pathResolver.service';
import { NotFoundComponent } from './general/not-found/not-found.component';
import { AlterarSenhaComponent } from './account/change-password/change-password.component';

import { BuyerGuard } from './_services/_guards/buyer.guard';
import { BuyerManagerGuard } from './_services/_guards/comprador-gestor.guard';
import { BuyerSupervisorGuard } from './_services/_guards/comprador-supervisor.guard';
import { ExternalGuard } from './_services/_guards/external.guard';
import { LoginGuard } from './_services/_guards/login.guard';
import { PrintPageComponent } from './general/print-page/print-page.component';
import { SellerGuard } from './_services/_guards/seller.guard';
import { SellerLocalManagerGuard } from './_services/_guards/vendedor-gestor-loja.guard';
import { SellerPartManagerGuard } from './_services/_guards/vendedor-gestor-peca.guard';
import { SupportGuard } from './_services/_guards/support.guard';
import { TermosUsoComponent } from './general/termos-uso/termos-uso.component';
import { getScreenSizeIsMobile } from './_utils/general-utils';
import { ManutencaoComponent } from './general/manutencao/manutencao.component';

const routes: Routes = !getScreenSizeIsMobile() ? [
    // **** IMPORTANTE **** Sempre que adicionar uma nova rota aqui, incluir também no arquivo _models/routes.ts **** IMPORTANTE ****

    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'alterar-senha', component: ExternalPageComponent, children: [{ path: '', component: AlterarSenhaComponent }] },
    { path: 'esqueci-senha', component: ExternalPageComponent, children: [{ path: '', component: EsqueciSenhaComponent }] },
    { path: 'login', component: ExternalPageComponent, canActivate: [LoginGuard], children: [{ path: '', component: LoginComponent }] },

    { path: 'comprador', canActivate: [BuyerGuard], loadChildren: () => import('./buyer/buyer.module').then(m => m.BuyerModule) },
    { path: 'gestor-comprador', canActivate: [BuyerManagerGuard], loadChildren: () => import('./repairer-manager/repairer-manager.module').then(m => m.RepairerManagerModule) },
    { path: 'gestor-loja', canActivate: [SellerLocalManagerGuard], loadChildren: () => import('./part-local-manager/part-local-manager.module').then(m => m.PartLocalManagerModule) },
    { path: 'gestor-peca', canActivate: [SellerPartManagerGuard], loadChildren: () => import('./part-manager/part-manager.module').then(m => m.PartManagerModule) },
    { path: 'supervisor-comprador', canActivate: [BuyerSupervisorGuard], loadChildren: () => import('./repairer-supervisor/repairer-supervisor.module').then(m => m.RepairerSupervisorModule) },
    { path: 'suporte', canActivate: [SupportGuard], loadChildren: () => import('./support/support.module').then(m => m.SupportModule) },
    { path: 'vendedor', canActivate: [SellerGuard], loadChildren: () => import('./seller/seller.module').then(m => m.SellerModule) },

    { path: 'pedido', canActivate: [ExternalGuard], loadChildren: () => import('./external/external.module').then(m => m.ExternalModule) },

    { path: 'imprimir', component: ExternalPageComponent, children: [ { path: '', component: PrintPageComponent } ] },
    {
        path: 'termos-uso', component: ExternalPageComponent, children: [
            { path: 'comprador', component: TermosUsoComponent },
            { path: 'vendedor', component: TermosUsoComponent }
        ]
    },

    { path: 'em-manutencao', component: ExternalPageComponent, children: [ { path: '', component: ManutencaoComponent } ] },

    { path: '', component: ExternalPageComponent, children: [{ path: '', component: LoginComponent }], pathMatch: 'full' },
    { path: '**', resolve: { path: PathResolveService }, component: ExternalPageComponent, children: [{ path: '', component: NotFoundComponent }] },
] : [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'alterar-senha', component: ExternalPageComponent, children: [{ path: '', component: AlterarSenhaComponent }] },
    { path: 'esqueci-senha', component: ExternalPageComponent, children: [{ path: '', component: EsqueciSenhaComponent }] },
    { path: 'login', component: ExternalPageComponent, canActivate: [LoginGuard], children: [{ path: '', component: LoginComponent }] },

    { path: 'pedido', canActivate: [ExternalGuard], loadChildren: () => import('./external/external.module').then(m => m.ExternalModule) },

    { path: 'vendedor', canActivate: [SellerGuard], loadChildren: () => import('./seller/seller.module').then(m => m.SellerModule) },

    { path: '', component: ExternalPageComponent, children: [{ path: '', component: LoginComponent }], pathMatch: 'full' },
    { path: '**', resolve: { path: PathResolveService }, component: ExternalPageComponent, children: [{ path: '', component: NotFoundComponent }] }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
