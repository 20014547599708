import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AccountService } from '../../account/account.service';
import { LocalStorageUtils } from '../../_utils/localstorage';
import { NotificationService } from 'app/_services/notification.service';

@Component({ selector: 'change-password', templateUrl: './change-password.component.html', styleUrls: ['./change-password.component.scss'] })

export class AlterarSenhaComponent implements OnInit
{
    model: any = { senha_atual: '', nova_senha: '', nova_senha_confirmacao: '' };
    alterarSenhaForm: UntypedFormGroup;

    isLoading: boolean = false;

    private localStorageUtils: LocalStorageUtils = new LocalStorageUtils();

    constructor(private accountService: AccountService, private notificationService:  NotificationService, private formBuilder: UntypedFormBuilder, private ngxLoader: NgxUiLoaderService, private router: Router,
        private translate: TranslateService) { }

    get f() { return this.alterarSenhaForm.controls; }

    ngOnInit()
    {
        this.alterarSenhaForm = this.formBuilder.group({
            senha_atual: ['', Validators.required],
            nova_senha: ['', [Validators.required, Validators.minLength(4)]],
            nova_senha_confirmacao: ['', [Validators.required, Validators.minLength(4)]]
        });
    }

    alterarSenha() {
        if (this.alterarSenhaForm.invalid) {
            if (this.f.nova_senha_confirmacao.errors) {
                if (this.f.nova_senha_confirmacao.errors.required)
                    this.translate.get('login.change-password.nova_senha_confirmacao.obrigatorio').subscribe(t => this.notificationService.showErrorToastr(t));
                else if (this.f.nova_senha_confirmacao.errors.minlength)
                    this.translate.get('login.change-password.nova_senha_confirmacao.comprimento_invalido').subscribe(t => this.notificationService.showErrorToastr(t));
                else if (this.f.nova_senha_confirmacao.errors.matchValueError)
                    this.translate.get('login.change-password.nova_senha_confirmacao.senha_e_confirmacao_diferentes').subscribe(t => this.notificationService.showErrorToastr(t));
            }

            if (this.f.nova_senha.errors) {
                if (this.f.nova_senha.errors.required)
                    this.translate.get('login.change-password.nova_senha.obrigatorio').subscribe(t => this.notificationService.showErrorToastr(t));
                else if (this.f.nova_senha.errors.minlength)
                    this.translate.get('login.change-password.nova_senha.comprimento_invalido').subscribe(t => this.notificationService.showErrorToastr(t));
            }

            if (this.f.senha_atual.errors) {
                if (this.f.senha_atual.errors.required)
                    this.translate.get('login.change-password.senha_atual.obrigatorio').subscribe(t => this.notificationService.showErrorToastr(t));
            }

            return;
        }

        this.isLoading = true;

        // Verifica se a senha digitada está correta
        this.accountService.alterarSenha(this.f.senha_atual.value, this.f.nova_senha.value).subscribe({
            next: (response: any) => {
                if (!response || !response.retorno) {
                    this.f.senha_atual.setValue('');
                    this.translate.get('login.change-password.mensagens.senha_atual_incorreta').subscribe(t => this.notificationService.showErrorToastr(t));
                    this.isLoading = false;

                    return;
                }

                this.f.senha_atual.setValue('');
                this.f.nova_senha.setValue('');
                this.f.nova_senha_confirmacao.setValue('');

                this.translate.get('login.change-password.mensagens.sucesso').subscribe(t => this.notificationService.showSuccessToastr(t));

                this.isLoading = false;

                setTimeout(() => { this.voltar(); }, 2000);
            },
            error: error => {
                console.log(error);

                if (error.status !== 500)
                    this.notificationService.showErrorToastr(error.error);
                else
                    this.translate.get('geral.erro.erro-desconhecido').subscribe(t => this.notificationService.showErrorToastr(t));;

                this.isLoading = false;
            }
        });
    }

    voltar()
    {
        var usuarioLogado = this.localStorageUtils.obterUsuario();
        var urlHomePage = '/login';

        if (usuarioLogado && usuarioLogado.homePage)
            urlHomePage = usuarioLogado.homePage;

        this.router.navigate([urlHomePage]);
    }
}
