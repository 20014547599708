import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, Route } from '@angular/router';

import { BaseGuard } from './base.guard';
import { AccountService } from '../../account/account.service';

declare const isEmpty: any;

@Injectable()
export class LoginGuard extends BaseGuard {
    constructor(protected router: Router, protected accountService: AccountService) { super(router, accountService); }

    canActivate(routeAc: ActivatedRouteSnapshot) {
        var token = routeAc.queryParams.token;
        var user_id = routeAc.queryParams.user_id;

        if (!isEmpty(token)) {
            // this.accountService.efetuarLoginWhiteLabel(token).subscribe({
            //     next: (response: any) =>
            //     {
            //         if (response != null && response.status)
            //         {
            //             var user = response.result;
            //             user.cashback = response.info_cashback;

            //             // Realiza o login
            //             this.accountService.setarUsuario(user, () =>
            //             {
            //                 var temAcesso = this.validarAcessoUserId(routeAc, user.id);

            //                 if (temAcesso)
            //                     this.navegarHomePage(user.homePage);
            //             });
            //         }
            //     },
            //     error: error => { console.log(error); }
            // });
        } else if (!isEmpty(user_id)) {
            // this.accountService.efetuarLoginById(user_id).subscribe({
            //     next: (response: any) =>
            //     {
            //         if (response != null && response.status)
            //         {
            //             response.user.homePage = response.telas[0].url;
            //             response.user.cashback = response.info_cashback;

            //             this.accountService.setarUsuario(response.user, () =>
            //             {
            //                 this.accountService.setarUsuarioDados(response.info_cashback, response.telas);
            //                 this.accountService.setarUrlsPermitidas(response.allowed_urls, 0);

            //                 this.accountService.navegarHomePage(response.user.homePage);
            //             });
            //         }
            //     },
            //     error : error => { console.log(error); }
            // });
        } else {
            let usuarioLogado = this.localStorageUtils.obterUsuario();

            // Se não tiver usuário logado, retorna que pode acessar a tela de login
            if (!usuarioLogado || isEmpty(usuarioLogado.homePage))
                return true;

            // Se o usuário já estiver logado, retorna para a home page dele
            this.navegarHomePage(usuarioLogado.homePage);
        }
    }
}
